<template>
    <div class="mtype-bottom-functions">
        <div v-if="user" @click="onClickProfile" class="bottom flex-row items-center cursor-pointer">
            <div class="img-cover flex-wrap" v-img-cover="user.photo_url" />
            <div>
                <span class="f-bold">{{ user.name }}</span
                >님 프로필
            </div>
            <i class="material-icons">chevron_right</i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MtypeBottomFunctions',
    props: ['user'],
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
    },
    methods: {
        async onClickProfile() {
            this.$stackRouter.push({
                name: 'AgentDetailPage',
                props: {
                    agentId: this.$store.getters.chat.agent_id,
                    from: 'ChatsPage',
                },
            })
        },
    },
}
</script>
