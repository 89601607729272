<template>
    <div class="action-open-chat">
        <ActionOpenChatIntroduce v-if="isIntroduce" :message="message" />
        <ActionOpenChatSocial v-else-if="isSocial" :message="message" :eventTitle="socialEventTitle" />

        <template v-else>
            <ActionOpenChatNormal :message="message" v-if="message.$$friendType === 'normal'" />
            <ActionOpenChatInstant v-else-if="message.$$friendType !== 'normal'" :message="message" />
        </template>
    </div>
</template>
<script>
import ActionOpenChatNormal from './ActionOpenChatNormal'
import ActionOpenChatInstant from './ActionOpenChatInstant'
import ActionOpenChatIntroduce from './ActionOpenChatIntroduce'
import ActionOpenChatSocial from './ActionOpenChatSocial'

export default {
    name: 'ActionOpenChat',
    props: ['message'],
    components: {
        ActionOpenChatNormal,
        ActionOpenChatInstant,
        ActionOpenChatIntroduce,
        ActionOpenChatSocial,
    },
    computed: {
        isIntroduce() {
            return this.message.mtype === 'open-chat-introduce'
        },
        chat() {
            return this.$store.getters.chat
        },
        isSocial() {
            return this.chat.chat_type === 'social_group'
        },
        socialEventTitle() {
            return this.chat.event.title || ''
        },
    },
}
</script>
