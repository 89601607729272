<template>
    <div class="action-open-chat-instant">
        <div class="title" v-html="title" />
        <div v-if="body" class="body" v-html="body" />
    </div>
</template>
<script>
export default {
    name: 'ActionOpenChatInstant',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        chatUser() {
            return this.chat.user || {}
        },
        title() {
            return this.$translate('ACTION_OPEN_CHAT_INSTANT_TITLE').replace(/%s/, `${this.chatUser.name}`)
        },
        body() {
            return this.$translate('ACTION_OPEN_CHAT_INSTANT_BODY')
        },
    },
}
</script>
