<template>
    <div class="action-open-chat-real-friend">
        <div class="title" v-html="title" />
        <div v-if="body" class="body" v-html="body" />
    </div>
</template>
<script>
export default {
    name: 'ActionOpenChatIntroduce',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        chatUser() {
            return this.chat.user || {}
        },
        me() {
            return this.$store.getters.me
        },
        isAgentUser() {
            return this.chat.introduce.user_id === this.me.id
        },
        title() {
            return this.$translate(
                `ACTION_OPEN_CHAT_REAL_FRIEND_${this.isAgentUser ? 'AGENT_' : ''}USER_TITLE`,
            ).replace(/%s/, `${this.content.title}`)
        },
        body() {
            return this.$translate(`ACTION_OPEN_CHAT_REAL_FRIEND_${this.isAgentUser ? 'AGENT_' : ''}USER_BODY`)
                .replace(/%s/, this.$nameOrNick(this.chatUser))
                .replace(/%s/, this.me.name || this.me.profile.nickname)
        },
    },
}
</script>
