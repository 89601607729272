<template>
    <div class="action-open-chat-normal">
        <div class="title" v-html="title" />
        <div class="body">
            <div v-html="$translate('ACTION_OPEN_CHAT_NORMAL_BODY')" />
            <div v-if="stats.length > 0" class="stats">
                <div class="stat-card flex-row flex-between m-b-8">
                    <div class="key" v-html="$translate(stats[0].key)" />
                    <div class="value c-primary" v-html="stats[0].value + stats[0].postfix" />
                </div>
            </div>
            <div class="open-chat-non-pro m-t-16" v-else v-html="actionOpenChatNonPro" />
        </div>
        <MtypeBottomFunctions :user="chat.user" />
    </div>
</template>

<script>
import MtypeBottomFunctions from './MtypeBottomFunctions'

export default {
    name: 'ActionOpenChatNormal',
    components: { MtypeBottomFunctions },
    props: ['message'],
    data: () => ({
        stats: [],
    }),
    computed: {
        actionOpenChatNonPro() {
            return this.$translate('ACTION_OPEN_CHAT_NON_PRO').replace(/%s/, this.chat.user.name)
        },
        chat() {
            return this.$store.getters.chat || {}
        },
        title() {
            return this.$translate('ACTION_OPEN_CHAT_NORMAL_TITLE').replace(
                /%s/,
                `${this.chat.user.name}<span class="f-regular">(${this.chat.user.nickname})</span>`,
            )
        },
    },
    created() {
        this.initAgentStats()
    },
    methods: {
        async initAgentStats() {
            const agent = await this.$store.dispatch('loadAgentDetail', { agentId: this.chat.agent_id })
            this.stats = [
                {
                    key: 'TOTAL_DATINGS',
                    value: agent.datings_stat.total_count,
                    postfix: '회',
                },
            ]
        },
    },
}
</script>
