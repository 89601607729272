<template>
    <div class="action-open-chat-social">
        <div class="title" v-html="title" />
        <div v-if="body" class="body" v-html="body" />
    </div>
</template>
<script>
export default {
    name: 'ActionOpenChatSocial',
    props: {
        message: Object,
        eventTitle: String,
    },
    computed: {
        title() {
            return this.$translate('ACTION_OPEN_CHAT_SOCIAL_TITLE').replace(/%s/, `${this.eventTitle}`)
        },
        body() {
            return this.$translate('ACTION_OPEN_CHAT_SOCIAL_BODY')
        },
    },
}
</script>
